<template>
  <div class="container">
    <AnimatedHeading text="¿Quiénes Somos?" />
    <div class="row">
      <div class="col-12 col-md">
        <p class="text-center">Javier Millapan, narrador legendario de Villarrica y maestro de las historias. Entre cada
          relato de mitología y aventuras, asegura que nunca se le acaba la inspiración(aunque a veces se le acabe el
          café). Conocido por su habilidad para mezclar mitos ancestrales con humor moderno, Javier es el que siempre
          tiene una historia bajo la manga… o al menos una buena excusa cuando llega tarde a las reuniones.</p>
      </div>
      <div class="col-12 col-md">
        <p class="text-center">Víctor Moraga, el mandamás del proyecto; el que sostiene los lápices y las cuentas.
          Conocido por su estilo detallado y por nunca olvidar el aguinaldo. Víctor no solo dibuja personales
          increíbles, sino que también se asegura de que el equipo sobreviva a fin de mes. Si lo ves con un lápiz en la
          mano, probablemente esté creando algo épico… o calculando cuantos completos pueden comer todos con el
          presupuesto</p>
      </div>
      <div class="col-12 col-md">
        <p class="text-center">David Muñoz Guzmán, el artista que lucha cada día para que el comic sea lo que el siempre
          soñó, aunque la mayor batalla ya la ganó: la lucha contra el alcohol. Su pasión por el cómic y su sentido del
          humor ácido lo convierten en una fuente inagotable de ideas.. algunas mas sobrias que otras, claro. Siempre
          con una sonrisa irónica y un trazo firme, David sigue intentando ser el dibujante que alguna vez pensó que
          era.</p>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedHeading from '@/components/AnimatedHeading.vue';
export default {
  name: 'TeamPage',
  components: {
    AnimatedHeading,
  },
};
</script>

<style scoped></style>