import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Importa el router
import 'bootstrap/dist/css/bootstrap.css'; // Importa Bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.js'; // Importa Bootstrap
import './assets/global.css';

const app = createApp(App);
app.use(router); // Usa el router aquí
app.mount('#app'); // Monta la aplicación
