<template>
  <div class="comic-page">
    <ChaptersMenu :chapters="chapters" @chapter-selected="selectChapter" />
    <ChapterContent v-if="selectedChapter !== null" :chapter="chapters[selectedChapter]" />
  </div>
</template>

<script>
import ChaptersMenu from '@/components/ChaptersMenu.vue';
import ChapterContent from '@/components/ChapterContent.vue';

export default {
  components: {
    ChaptersMenu,
    ChapterContent
  },
  data() {
    return {
      selectedChapter: null,
      chapters: [
        {
          title: "Tralkan",
          image: require("@/assets/narracion_1/cover.jpg"),
          text: "<p>En un bosque de guayes se encontraba un pichigüentru jugando con pinatras y digüeñes. Mientras recogía los frutos que le regalan la ñuque mapu, encuentra una piedra, la misma que en sus sueños alguna vez vio. </p><p>Asombrado por su descubrimiento, raudamente corre a la ruca donde se encuentra su abuelito. Allí el anciano, sólo con ver lo que su nieto llevaba en las manos, cambió el semblante. Se sentó en un  guanco, tomó en sus manos un viejo bastón de arrayán y le dijo, «Dime, mi pichigüentru, ¿qué cosa te aqueja tanto que te hizo dejar de jugar por venir a la sombra de tu abuelo?».</p><p> El pequeño responde, «Abuelito, abuelito, esta piedra que encontré alguna vez la tuve, pero en un sueño. Por favor, cuéntame qué es. Escucha atentamente», le dijo el abuelo, «lo que te contaré es el quimún de mi padre». Y a la vez él lo escuchó del quimún de su abuelo, es el quimún de generaciones.Esa roca en un rayo estuvo, del cielo cayó, pudo romper otras rocas y romper y cortar el guaye más anciano. Luego se incrustó en la tierra y el tiempo y las generaciones le entregaron un nehuén. Ese nehuén la hizo subir para que un portador la encuentre. Los grandes toquis llevaron una de ella: Lautaro, Caupolicán, Calfucura. Grandes señores y estrategas la portaron y hoy veo al gran Toqui que la portará.</p><p> El niño sin comprender mucho la sostuvo con orgullo y el anciano abuelo, más allá de la sombra de su nieto, observó al gran estratega en que se convertiría. Nehuén cura, piedra de poder utilizada por grandes toquis. También en algunas localidades la llaman Lufque o Yifque, que en mapuzungún significa Rayo.</p>",
          audio: require("@/assets/narracion_1/audio.mp3"),
          images: [
            { src: require('@/assets/narracion_1/Tralkan01.jpg'), time: 0 },
            { src: require('@/assets/narracion_1/Tralkan02.jpg'), time: 11 },
            { src: require('@/assets/narracion_1/Tralkan03.jpg'), time: 49 },
            { src: require('@/assets/narracion_1/Tralkan04.jpg'), time: 70 },
            { src: require('@/assets/narracion_1/Tralkan05.jpg'), time: 125 },
          ],
        },
        {
          title: "Wekufe",
          text: "Texto del capítulo Wekufe...",
          image: require("@/assets/narracion_2/cover.jpg"),
          audio: null,
          images: [],
        },
        {
          title: "Witranalhue",
          text: "Texto del capítulo Witranalhue...",
          image: require("@/assets/narracion_3/cover.jpg"),
          audio: null,
          images: [],
        },
        {
          title: "Machi Llancahue",
          text: "Texto del capítulo Machi Llancahue...",
          image: require("@/assets/narracion_4/cover.jpg"),
          audio: null,
          images: [],
        },
        {
          title: "Cherrufe",
          text: "Texto del capítulo Cherrufe...",
          image: require("@/assets/narracion_5/cover.jpg"),
          audio: null,
          images: [],
        },
        {
          title: "Nahuel",
          text: "Texto del capítulo Nahuel...",
          image: require("@/assets/narracion_6/cover.jpg"),
          audio: null,
          images: [],
        },
        {
          title: "Llallinkuse",
          text: "Texto del capítulo Llallinkuse...",
          image: require("@/assets/narracion_7/cover.jpg"),
          audio: null,
          images: [],
        },
        {
          title: "Anchimallen",
          text: "Texto del capítulo Anchimallen...",
          image: require("@/assets/narracion_8/cover.jpg"),
          audio: null,
          images: [],
        }
      ]
    };
  },
  methods: {
    selectChapter(index) {
      this.selectedChapter = index;
    }
  }
};
</script>

<style scoped>
.comic-page {
  padding: 20px;
}
</style>
