<!-- src/components/AnimatedTitle.vue -->
<template>
  <h1 ref="title" class="animated-title py-5">
    {{ text }}
  </h1>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "AnimatedHeading",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.animateTitle();
  },
  methods: {
    animateTitle() {
      const title = this.$refs.title;
      
      gsap.fromTo(
        title,
        { opacity: 0, y: -50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power3.out",
        }
      );
    },
  },
};
</script>

<style scoped>
.animated-title {
  opacity: 0; /* Inicialmente oculto */
  transform: translateY(20px); /* Desplazado hacia abajo */
  text-align: center; /* Centrar el texto */
}
</style>
