<template>
 <div class="container">
  <AnimatedHeading text="Contáctanos" />
  <div class="row">
    <!-- Se ajusta el tamaño de las columnas según el tamaño de la pantalla -->
    <div class="col-md-6 col-12 text-center">
      <p>¡Atención, amantes de la mitología y los cómics!</p>
      <p>🌿 <strong>Tralkan, susurros de la Ñukemapu</strong> 🌿 te llevará a un viaje épico por las leyendas
        mapuches, mezclando aventura, misterio y poder ancestral en cada página. Si te gustan las historias profundas
        y batallas entre lo humano y lo mágico, este comic es para ti.</p>
      <p>¡No te pierdas la oportunidad de sumergirte en un mundo donde los espíritus y el Newen se entrelazan con lo
        cotidiano!.</p>
      <p>Contáctanos y descubre más sobre este proyecto lleno de identidad y fuerza.</p>
    </div>
    <div class="col-md-6 col-12">
      <form @submit.prevent="sendEmail">
        <div class="mb-3">
          <label for="exampleFormControlInput0" class="form-label">Nombre</label>
          <input type="text" class="form-control" id="exampleFormControlInput0" v-model="form.name" required>
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Correo electrónico</label>
          <input type="email" class="form-control" id="exampleFormControlInput1" v-model="form.email" required>
        </div>
        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label">Comentario o mensaje</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="form.message"
            required></textarea>
        </div>
        <div class="mb-3">
          <button type="submit" class="btn btn-primary">Enviar</button>
        </div>
      </form>
      <p v-if="successMessage">{{ successMessage }}</p>
      <p v-if="errorMessage">{{ errorMessage }}</p>
    </div>
  </div>
</div>

</template>

<script>
import AnimatedHeading from '@/components/AnimatedHeading.vue';
export default {
  name: 'ContactPage',
  components: {
    AnimatedHeading,
  },
  // eslint-disable-next-line no-unused-vars
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: '',
      },
      successMessage: '',
      errorMessage: '',
      recaptchaToken: '',
      siteKey: '6LcbZUoqAAAAAPIr0rVnaXko_hhPbU74zf3DgTuV',
      recaptchaWidgetId: null,
    };
  },
  mounted() {
    /*
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
 
    script.onload = () => {
      this.initializeRecaptcha();
    };*/
  },
  methods: {
    /*initializeRecaptcha() {
      if (this.recaptchaWidgetId !== null) {
        // Si ya está renderizado, no lo vuelvas a renderizar
        return;
      } 

      window.grecaptcha.ready(() => {
        this.recaptchaWidgetId = window.grecaptcha.render('g-recaptcha', {
          sitekey: this.siteKey,
          callback: this.onRecaptchaSuccess,
        });
      });
    },
    onRecaptchaSuccess(token) {
      this.recaptchaToken = token;
    },*/
    sendEmail() {
      /*if (!this.recaptchaToken) {
      alert('Completa el reCAPTCHA.');
      return;
    }*/

      const formData = {
        ...this.form,
        //recaptchaToken: this.recaptchaToken
      };

      fetch('http://localhost:3000/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          console.log(response);
          if (!response.ok) {
            return response.text().then(text => { throw new Error(text || 'Error al enviar el correo'); });
          }
          return response.text();
        })
        .then((data) => {
          console.log('Correo enviado:', data);
          this.successMessage = 'Correo enviado con éxito!';
          this.errorMessage = '';
          this.form = { name: '', email: '', message: '' }; // Resetea el formulario
        })
        .catch((error) => {
          console.error('Error en el envío:', error.message);
          this.errorMessage = error.message;
          this.successMessage = '';
        });
    },
  },
};
</script>

<style scoped>
/* Estilos para el formulario */
</style>