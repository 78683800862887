<template>
  <div class="chapter-content">
    <h2>{{ chapter.title }}</h2> 

    <div class="image-carousel">
      <!-- Ajusta la imagen para que ocupe toda la altura disponible -->
      <img :src="currentImage" alt="Imagen del capítulo" />
    </div>

    <div class="text-center">
      <audio ref="audio" controls @timeupdate="checkTime">
        <source :src="chapter.audio" type="audio/mpeg" />
        Tu navegador no soporta audio HTML5.
      </audio>
    </div>
  </div>
</template>

<script>
export default {
  props: ["chapter"],
  data() {
    return {
      currentImage: this.chapter.images[0].src
    };
  },
  mounted() {
    this.audioElement = this.$refs.audio; // Referencia al elemento de audio
  },
  beforeUnmount() {
    // Eliminar el evento timeupdate antes de destruir el componente
    if (this.audioElement) {
      this.audioElement.removeEventListener('timeupdate', this.checkTime);
      this.audioElement.pause(); // Detiene el audio si está reproduciéndose
      this.audioElement.currentTime = 0; // Reinicia el audio
    }
  },
  methods: {
    checkTime() {
      const audioTime = this.audioElement.currentTime;
      const nextImage = this.chapter.images.find(
        (image) => audioTime >= image.time && audioTime < image.time + 30
      );
      if (nextImage && nextImage.src !== this.currentImage) {
        this.currentImage = nextImage.src;
      }
    }
  }
};
</script>

<style scoped>
.chapter-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.image-carousel {
  flex: 1; /* Permite que la imagen ocupe todo el espacio disponible */
} 

.image-carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Hace que la imagen se ajuste manteniendo la proporción */
  display: block;
  margin: 0 auto;
  transition: opacity 0.5s ease-in-out; /* Transición suave */
}
.text-center {
  margin-top: 20px;
}

audio {
  width: 100%;
}
</style>
