<template>
  <div id="app" class="app-container">
    <header>
      <nav class="navbar navbar-expand-lg" v-if="!$route.meta.hideNav">
        <div class="container-fluid">
          <a class="navbar-brand" href="/home">
            <img src="@/assets/logo.png" width="30px" height="30px" alt="Tralkan"
              class="d-inline-block align-top bg-logo" />
            Tralkan Comic
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNav"
            aria-controls="offcanvasNav">
            <div class="guñelve-toggle">
              <img src="@/assets/gunelve.svg" class="guñelve" />
            </div>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <a class="nav-link" href="/home">Inicio</a>
              </li>
              <li class="nav-item">
                <a class="nav-link highlight" href="/comic">Comic</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/quienes-somos">Quienes somos</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/contactanos">Contáctanos</a>
              </li>
              <!-- Más enlaces aquí -->
            </ul>
          </div>
        </div>
      </nav>

      <!-- Offcanvas para las opciones de navegación en móvil -->
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNav" aria-labelledby="offcanvasNavLabel">
        <div class="offcanvas-header">
          <h5 id="offcanvasNavLabel">Tralkan Comic</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav flex-column">
            <li class="nav-item">
              <a class="nav-link" href="/home">Inicio</a>
            </li>
            <li class="nav-item">
              <a class="nav-link highlight" href="/comic">Comic</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/quienes-somos">Quienes somos</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/contactanos">Contáctanos</a>
            </li>
            <!-- Más enlaces aquí -->
          </ul>
        </div>
      </div>
    </header>
    <div class="content">
      <main>
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </main>
    </div>
    <footer v-if="!$route.meta.hideNav">
      <div class="container">
        <div class="row">
          <!-- Sección del logo y texto -->
          <div class="py-4 col-md-6 mb-3 mb-md-0 d-flex flex-column flex-md-row align-items-center">
            <img src="@/assets/TralkanLogo.png" alt="Logo" class="footer-logo mb-2 mb-md-0 me-md-3" />
            <p class="mb-0 text-center text-md-start">
              Tralkan Cómic, proyecto financiado por el Fondo de Cultura del Ministerio de las Culturas, las Artes y el
              Patrimonio.
            </p>
          </div>

          <!-- Imagen en el otro extremo, alineada a la base en pantallas pequeñas -->
          <div class="col-md-6 text-md-end text-center align-self-end">
            <img src="@/assets/mincap-footer.svg" alt="Imagen de pie de página" class="footer-image" />
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
.bg-logo {
  background-color: white !important;
}  
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.guñelve-toggle {
  background: none;
  border: none;
  cursor: pointer;
}

.guñelve {
  transition: transform 0.3s ease;
}

.guñelve-toggle:hover .guñelve {
  transform: rotate(360deg); 
}

/* Animación para las opciones del nav */
.navbar-nav .nav-link {
  transition: transform 0.3s ease;
}

.navbar-collapse.show .nav-link {
  transform: translateX(5px);
  /* Desplazamiento a la derecha */
}

/* Estilo para el logo */
.footer-logo {
  width: 100px;
  height: auto;
}

/* Estilo para la imagen del pie de página */
.footer-image {
  width: 120px;
  height: auto;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  padding-bottom: 10px;
}
</style>
