<template>
  <div class="hero">
    <img class="background-svg" src="@/assets/portada.svg" alt="Fondo SVG" />
    <div class="content">
      <h1 ref="title" class="title-text display-1"></h1>
      <h2 ref="subtitle" class="title-text pb-5"></h2>
      <h3 v-for="(author, index) in authors" :key="index" ref="subtitles">{{ author }}</h3>
      <button 
        v-if="showButton" 
        @click="enterSite"
        ref="button"
      >      
        <img src="@/assets/gunelve.svg" width="30px" height="30px" />
        Próximamente
      </button> 
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: 'InicioPage',
  data() {
    return {
      showButton: false,
      titleText: "Tralkan",
      subtitleText: "Susurros de la ÑukeMapu",
      authors: ["Millapan", "Moraga", "Muñoz"], // Lista de autores
    };
  },
  mounted() {
    this.animateText();
  },
  methods: {
    animateText() {
      const title = this.$refs.title;
      const subtitle = this.$refs.subtitle;

      title.textContent = this.titleText;
      subtitle.textContent = this.subtitleText;

      // Animación del título
      gsap.fromTo(
        title,
        { opacity: 0, y: -50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power3.out",
          onComplete: () => {
            this.animateSubtitles();
          },
        }
      );

      // Animación del subtítulo
      gsap.fromTo(
        subtitle,
        { opacity: 0, y: -50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power3.out",
          onComplete: () => {
            this.animateSubtitles();
          },
        }
      );
    },
    animateSubtitles() {
      const subtitles = this.$refs.subtitles;

      gsap.fromTo(
        subtitles,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "power3.out",
          stagger: 0.3,
          onComplete: () => {
            this.showButton = true;
            this.$nextTick(() => {
              this.animateButton();
            });
          },
        }
      );
    },
    animateButton() {
      gsap.fromTo(
        this.$refs.button,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.5, ease: "power3.out", delay: 0.2 }
      );
    },
    enterSite() {
      // this.$router.push('/home');
    },
  },
};
</script>

<style scoped>
.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden; 
}

.background-svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto; 
  height: 100%; 
  transform: translate(-50%, -50%);
  opacity: 0.1;  
}

.content {
  position: relative;
  z-index: 2; 
  text-align: center; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; 
}

.title-text {
  opacity: 0; /* Inicialmente oculto */
  transform: translateY(20px); /* Desplazados hacia abajo */
}

h3 {
  opacity: 0; /* Inicialmente ocultos */
  transform: translateY(20px); /* Desplazados hacia abajo */
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;  
  background-color: #04127b;
  color: white;
  border: none;
  border-radius: 5px; 
  opacity: 0;
  transform: translateY(20px);
} 
</style>
