<template>
  <div class="container"> 
    <AnimatedHeading text="Tralkan, Susurros de la Ñukemapu" /> 
    <div class="row align-items-stretch">
      <div class="col-md-3 video-container"> <!-- Ajusta el tamaño aquí -->
        <video controls autoplay muted class="rounded-video">
          <source src="@/assets/lectura.mp4" type="video/mp4">
          Tu navegador no soporta la etiqueta de video.
        </video>
      </div>
      <div class="col-md-9 text-container"> <!-- Ajusta el tamaño aquí -->
        <p class="lead">Tralkan, susurros de la Ñukemapu es una historia inmersa en el vasto mundo de la mitología y leyendas mapuches, donde la conexión entre el mundo espiritual y la naturaleza es profunda.</p>
        <p class="lead">Tralkan, el protagonista, es un ser cuya vida se entrelaza con las fuerzas de la tierra, el cielo y los susurros de los espíritus ancestrales. Este viaje lo lleva a descubrir secretos antiguos y a enfrentarse a fuerzas oscuras que amenazan el equilibrio de la Ñukemapu, la tierra madre.</p>
        <p class="lead">En el corazón del relato, los personajes se encuentran en un constante diálogo con los elementos y los antiguos espíritus del territorio, donde cada paso de Tralkan revela una verdad oculta o un misterio que desafía su comprensión. A medida que se adentra en los mitos y leyendas que han dado forma al pueblo mapuche, Tralkan debe aprender a usar los dones que ha heredado y enfrentar las entidades poderosas que protegen y a veces atacan a su gente.</p>
        <p class="lead">La lucha por mantener el equilibrio entre los hombres y la Ñukemapu se convierte en el eje de la historia, donde el destino de la comunidad y de las fuerzas naturales pende de un hilo. Tralkan deberá decidir entre seguir su propio camino o aceptar el llamado de los espíritus para convertirse en el protector de las tierras ancestrales y enfrentarse a las amenazas que se ciernen sobre su mundo.</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.video-container {
  display: flex;
  justify-content: center;
}

video {
  width: 100%; /* Ocupa el 100% del contenedor */
  height: auto; /* Mantiene la proporción del video */
  max-height: 100%; /* Limita la altura al tamaño del contenedor */ 
}

.rounded-video{ 
  border-radius: 15px;
 }

 .text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

<script>
import AnimatedHeading from '@/components/AnimatedHeading.vue';
export default {
  name: 'HomePage',
  components: {
    AnimatedHeading,
  },
};
</script>
 
