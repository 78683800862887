<template>
  <div class="chapters-menu">
    <h3>Capítulos</h3>
    <div class="row chapters-list">
      <div
        class="col-12 col-md-6 col-lg-4 chapter-item"
        v-for="(chapter, index) in chapters"
        :key="index"
        ref="chapterItems"
        @click="openChapter(index)"
      >
        <div class="chapter-background" :style="{ backgroundImage: 'url(' + chapter.image + ')' }">
          <div class="overlay"></div> <!-- Capa de oscurecimiento -->
          <div class="chapter-title">{{ chapter.title }}</div>
        </div>
      </div>
    </div>

    <!-- Ficha de detalles estilo Netflix -->
    <div v-if="selectedChapter !== null" class="chapter-details" ref="chapterDetails">
      <div class="chapter-cover" :style="{ backgroundImage: 'url(' + chapters[selectedChapter].image + ')' }"></div>
      <div class="chapter-info">
        <h2>{{ chapters[selectedChapter].title }}</h2>
        <div class="chapter-text" v-html="chapters[selectedChapter].text"></div>
        <div class="buttons">
          <button class="btn btn-primary" @click="openAudioChapter">AudioCapítulo</button>
          <button class="btn btn-danger" @click="closeChapter">Cerrar</button>
        </div>
      </div>
    </div>

    <!-- Modal para el componente ChapterContent -->
    <div v-if="showModal" class="modal-backdrop">
      <div class="modal-content">
        <button class="btn btn-danger close-modal" @click="closeModal">Cerrar</button>
        <ChapterContent :chapter="chapters[selectedChapter]" />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import ChapterContent from '@/components/ChapterContent.vue';

export default {
  components: {
    ChapterContent,
  },
  props: ["chapters"],
  data() {
    return {
      selectedChapter: null, // Almacena el capítulo seleccionado
      showModal: false, // Controla la visibilidad del modal
    };
  },
  methods: {
    openChapter(index) {
      this.selectedChapter = index;
      this.$nextTick(() => {
        gsap.fromTo(
          this.$refs.chapterDetails,
          { opacity: 0, y: 50 },
          { opacity: 1, y: 0, duration: 0.8, ease: "power3.out" }
        );
      });
    },
    closeChapter() {
      gsap.to(this.$refs.chapterDetails, {
        opacity: 0,
        y: 50,
        duration: 0.8,
        ease: "power3.out",
        onComplete: () => {
          this.selectedChapter = null;
        },
      });
    },
    openAudioChapter() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.chapters-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.chapter-item {
  margin: 10px;
  cursor: pointer;
  position: relative;
}

.chapter-background {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.5s ease;
}

.chapter-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-size: 1.2rem;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
}

/* Estilo para la ficha de detalles estilo Netflix */
.chapter-details {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 20px;
  z-index: 10;
  max-height: 90vh;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  flex-direction: column; /* Cambiamos para que la información se alinee verticalmente */
}

.chapter-cover {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  margin-bottom: 20px;
}

/* Ajustes para que el contenido ocupe todo el espacio disponible y sea desplazable */
.chapter-info {
  flex: 1;
  overflow-y: auto; /* Permitir el scroll si el texto es demasiado largo */
  display: flex;
  flex-direction: column;
}

.chapter-text {
  flex: 1;
  margin-bottom: 10px;
  overflow-y: auto; /* Hacer que el texto se pueda desplazar */
}

.buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-top: auto; /* Empujar los botones al final del contenedor */
}

/* Estilo para el modal */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  height: 80vh;
  overflow-y: auto;
  position: relative;
}

.modal-content img {
  width: 100%;
  height: 100%;
  max-height: 80vh; /* Ajusta la imagen al 80% de la altura del viewport */
  object-fit: contain; /* Mantiene la proporción de la imagen */
  display: block;
  margin: 0 auto;
}
.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (max-width: 768px) {
  .chapter-details {
    max-height: auto;
  }
}
</style>
