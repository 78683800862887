import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';  
import TeamPage from '../views/TeamPage.vue';  
import ContactPage from '../views/ContactPage.vue';  
import InicioPage from '../views/InicioPage.vue';   
import ComicPage from '../views/ComicPage.vue';  

const routes = [
  
  {
    path: '/',
    name: 'Inicio',
    component: InicioPage,
    meta: { hideNav: true } 
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/quienes-somos',
    name: 'Quienes',
    component: TeamPage,
  },
  {
    path: '/comic',
    name: 'Comic',
    component: ComicPage,
  },
  {
    path: '/contactanos',
    name: 'Contactanos',
    component: ContactPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
